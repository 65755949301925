import React, { useState } from 'react';
import { makeStyles } from '@griffel/react';

const useClasses = makeStyles({
  textDiv: { textIndent: '1em'},
  header: { backgroundColor: '#b90006', marginTop: 0, paddingTop: '1em', paddingLeft: '1em', paddingBottom: '1em' },
  headerText: { marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0, fontSize: '2.8em', fontWeight: 'normal', textTransform: 'uppercase', paddingLeft: '3', display: 'inline-block', color: 'white'},
  listDiv: {display: 'block'},
  listText: {borderBottomWidth: 'thin', borderBottomStyle: 'solid', borderBottomColor: '#820000', borderBottom: '1px solid', marginTop: '10px'},
  link: {':link': {textDecorationLine: 'none', color: '#29074a'}, ':hover': {textDecorationLine: 'underline', color: '#7d17e3'}, ':visited': {textDecorationLine: 'none', color: '#986ec4'}}
});

const populateNews = async (state) => {
  const response = await fetch('news');
  const data = await response.json();
  state(data);
}

const FetchData = () => {
  const [news, setNews] = useState([])
  const classes = useClasses()

  const contents = news.length === 0
      ? <p><em>Loading...</em></p>
      : (<div className={classes.listDiv}>
        {news.map( nice => <div className={classes.listText}><a className={classes.link} href={nice.link}><b>{nice.title}</b><p>{nice.description}</p></a></div>)}
      </div>);

  if (news.length === 0)
    populateNews(setNews)


  return (
    <div className={classes.textDiv}>
      <div className={classes.header}>
        <h1 className={classes.headerText}>da nuwus</h1>
      </div>
      {contents}
    </div>
  );
}

export default FetchData